<div class="whole-page">
    <main>
        <section class="top">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-1"></div>
                    <div class="grid-item-2"></div>
                    <div class="grid-item-3"></div>
                    <div class="grid-item-4"></div>
                    <div class="grid-item-5"></div>
                    <div class="grid-item-6"></div>
                    <div class="grid-item-7"></div>
                    <div class="grid-item-8"></div>
                    <div class="grid-item-9"></div>
                    <div class="grid-item-10"></div>
                    <div class="grid-item-11"></div>
                    <div class="grid-item-12"></div>
                    <div class="grid-item-13"></div>
                    <div class="grid-item-14"></div>
                    <div class="grid-item-15"></div>
                    <div class="grid-item-16"></div>
                    <div class="grid-item-17"></div>
                </div>
            </div>
            <div class="content">
                <h1><a href="https://coastsidejewishcommunity.org/member">CJC Members</a></h1>
                <div class="main-text">
                    <p>This app replaced the paper contact list that was
                        distributed yearly to CJC members. I added
                        other features such as generating spreadsheets from the database, email lists by region,
                        and tracking membership status.
                        Several members have administrator status, and they can create and edit information.
                        The site's authentication is based on both password and the current status of the member.
                    </p>
                </div>
            </div>
        </section>
        <section class="second-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-20"></div>
                    <div class="grid-item-21"></div>
                    <div class="grid-item-22"></div>
                    <div class="grid-item-23"></div>
                    <div class="grid-item-24"></div>
                    <div class="grid-item-25"></div>
                    <div class="grid-item-26"></div>
                    <div class="grid-item-27"></div>
                    <div class="grid-item-28"></div>
                    <div class="grid-item-29"></div>
                    <div class="grid-item-30"></div>
                    <div class="grid-item-31"></div>
                    <div class="grid-item-32"></div>
                    <div class="grid-item-33"></div>
                    <div class="grid-item-34"></div>
                    <div class="grid-item-35"></div>
                    <div class="grid-item-36"></div>
                    <div class="grid-item-37"></div>
                    <div class="grid-item-38"></div>
                    <div class="grid-item-39"></div>
                    <div class="grid-item-40"></div>
                    <div class="grid-item-41"></div>
                    <div class="grid-item-42"></div>
                    <div class="grid-item-43"></div>
                    <div class="grid-item-44"></div>
                    <div class="grid-item-45"></div>
                    <div class="grid-item-46"></div>
                    <div class="grid-item-47"></div>
                    <div class="grid-item-48"></div>
                    <div class="grid-item-49"></div>
                    <div class="grid-item-51"></div>
                    <div class="grid-item-52"></div>
                    <div class="grid-item-53"></div>
                    <div class="grid-item-54"></div>
                    <div class="grid-item-55"></div>
                    <div class="grid-item-56"></div>
                    <div class="grid-item-57"></div>
                    <div class="grid-item-58"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/cjcfaqmenuPhone.png" class="cjc-phone"
                    alt="CJC Members FAQ on a phone">
                <img src="assets/img/screenshots/cjcfaqmenu2.png" class="cjc-faqmenu" alt="CJC Members FAQ and menu">
                <div class="main-text">
                    <p>The Yahrzeits module keeps a database of deceased
                        loved ones to be remembered on the anniversaries of their deaths. Reminders are emailed to
                        members.
                        I made this site entirely myself using RoR. Since the site's creation, I've updated the versions
                        of Ruby and Rails several times.</p>
                </div>
            </div>
        </section>
        <section class="third-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-79"></div>
                    <div class="grid-item-80"></div>
                    <div class="grid-item-81"></div>
                    <div class="grid-item-82"></div>
                    <div class="grid-item-83"></div>
                    <div class="grid-item-84"></div>
                    <div class="grid-item-85"></div>
                    <div class="grid-item-86"></div>
                    <div class="grid-item-87"></div>
                    <div class="grid-item-88"></div>
                    <div class="grid-item-89"></div>
                    <div class="grid-item-90"></div>
                    <div class="grid-item-91"></div>
                    <div class="grid-item-92"></div>
                    <div class="grid-item-93"></div>
                    <div class="grid-item-94"></div>
                    <div class="grid-item-95"></div>
                    <div class="grid-item-96"></div>
                    <div class="grid-item-97"></div>
                    <div class="grid-item-98"></div>
                    <div class="grid-item-99"></div>
                    <div class="grid-item-100"></div>
                    <div class="grid-item-101"></div>
                    <div class="grid-item-102"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/cjcYahrzeitsObscured3.png" class="cjc-yahrzeits"
                    alt="Yahrzeits (names obscured for this image)">

                <div class="tip-phone">
                    <img src="assets/img/screenshots/cjcYahrzeitsPhone.png" class="cjc-yahrzeits-phone"
                        alt="CJC Members yahrzeits shown on a phone">
                </div>
            </div>
        </section>
    </main>
</div>