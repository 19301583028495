<div class="whole-page">
    <main>
        <section class="top">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-1"></div>
                    <div class="grid-item-2"></div>
                    <div class="grid-item-3"></div>
                </div>
            </div>
            <div class="content">
                <h1>Northern Light Studio LLC</h1>
                <div class="main-text">
                    <p><a href="http://www.northernlightstudio.com/" target="_blank">www.northernlightstudio.com</a>
                        is investigating, practicing, and teaching historic painting and sculpture techniques. 
                        Northern Light Studio documents and demonstrates historical art techniques and materials. 
                        This scholarly site details pigments and techniques, with a glossaries and sources.
                        The site uses Angular.js and is responsive.</p>
                </div>
                <div class="center">
                    <img src="assets/img/screenshots/nls-home-phone.png" class="nls-home-phone" alt="Northern Light Studio homepage on a phone">  
                </div>
                </div>
        </section>
        <section class="second-section">
                <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-21"></div>
                    <div class="grid-item-22"></div>
                    <div class="grid-item-23"></div>
                    <div class="grid-item-24"></div>
                    <div class="grid-item-25"></div>
                    <div class="grid-item-26"></div>
                    <div class="grid-item-27"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/nls-home.png" class="nls-home" alt="Northern Light Studio homepage">
            </div>
        </section>
        <section class="third-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-32"></div>
                    <div class="grid-item-33"></div>
                    <div class="grid-item-35"></div>
                    <div class="grid-item-36"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/nls-rembrandt.png" class="nls-rembrandt" alt="Rembrandt and Burnt Plate Oil"> 
            </div>
        </section>
    </main>
</div>