<div class="whole-page">
    <main>
        <section class="top">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-1"></div>
                    <div class="grid-item-2"></div>
                    <div class="grid-item-3"></div>
                    <div class="grid-item-4"></div>
                    <div class="grid-item-5"></div>
                    <div class="grid-item-6"></div>
                    <div class="grid-item-7"></div>
                    <div class="grid-item-8"></div>
                    <div class="grid-item-9"></div>
                </div>
            </div>
            <div class="content">
                <h1>Yodlr</h1>
                <div class="main-text">
                    <p>Yodlr was a startup that used WebRTC for meetings.
                    I coded the front-end using HTML, CSS, and Angular.js. I also created the design and images.</p>
                    <p>Users had audio meetings through the browser without downloading an app. Meetings could be recorded, files could be uploaded and shared, and there was a text chat as well as an audio.
                    A user could present a document to the group and members could join and leave presentations.</p>
                </div>
                <img src="assets/img/screenshots/yodPhone.png" class="yodlr-phone" alt="Yodlr shown on a phone">
            </div>
        </section>
        <section class="second-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-22"></div>
                    <div class="grid-item-23"></div>
                    <div class="grid-item-24"></div>
                    <div class="grid-item-25"></div>
                    <div class="grid-item-26"></div>
                    <div class="grid-item-27"></div>
                    <div class="grid-item-28"></div>
                    <div class="grid-item-29"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/yodRoom.png" class="yodlr-room" alt="Yodlr meeting room">
           </div>
        </section>
        <section class="third-section">
            <div class="content">
                <div class="main-text">
                    <p>A user could drag to resize areas of the meeting panels.</p>
                    <p>The image on the bottom shows the marketing side of the app on a wide screen (to show the decorative background.)</p>
                </div>
                <img src="assets/img/screenshots/marketing.png" class="yodlr-marketing" alt="Yodlr landing page with decorative areas for extra large screens">
            </div>
        </section>
    </main>
</div>