<div class="whole-page">
    <main>
        <section class="top">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-1 line"></div>
                    <div class="grid-item-2 line"></div>
                    <div class="grid-item-3 line"></div>
                    <div class="grid-item-4 line"></div>
                    <div class="grid-item-5 line"></div>
                    <div class="grid-item-6 line"></div>
                    <div class="grid-item-7 line"></div>
                    <div class="grid-item-8 line"></div>
                    <div class="grid-item-9 line"></div>
                    <div class="grid-item-10 line"></div>
                    <div class="grid-item-11 line"></div>
                    <div class="grid-item-12 line"></div>
                    <div class="grid-item-13 line"></div>
                    <div class="grid-item-14 line"></div>
                    <div class="grid-item-15 line"></div>
                    <div class="grid-item-16 line"></div>
                </div>
            </div>
            <div class="content">
                <h1>Sarah Belchetz-Swenson</h1>
                <div class="main-text">
                    <p>Sarah Belchetz-Swenson is an artist with a large body of varied work.
                            She wanted <a href="http://www.belchetz-swenson.com" target="_blank">Belchetz-Swenson.com</a>
                            to have the feel of a traditional museum, thus communicating the timeless quality of the pieces.
                            The site uses Angular.js and has a form for the artist to add, edit, delete, and reorder her work.</p>
                </div>
                <img src="assets/img/screenshots/phoneGG.png" class="sbs-phoneGG" alt="Sarah Belchetz-Swenson portrait">
                <img src="assets/img/screenshots/winterLandscapeMed.png" class="sbs-landscape" alt="Sarah Belchetz-Swenson landscape">
            </div>
        </section>
        <section class="second-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                        <div class="grid-item-30 line"></div>
                        <div class="grid-item-31 line"></div>
                        <div class="grid-item-32 line"></div>
                        <div class="grid-item-33 line"></div>
                        <div class="grid-item-34 line"></div>
                        <div class="grid-item-35 line"></div>
                        <div class="grid-item-36 line"></div>
                        <div class="grid-item-37 line"></div>
                        <div class="grid-item-38 line"></div>
                        <div class="grid-item-39 line"></div>
                </div>
            </div>
            <div class="content">
                <div class="main-text">
                    <blockquote>
                        <p>&quot;As an artist, I cannot imagine a better presentation of my work on-line than the web site that
                            Miriam Salzer has designed and built for me.</p>
                        <p>Her intuitive understanding of my work and her responsiveness to my ideas, coupled with her imagination,
                            taste and technical skills made it a real pleasure to work with her with from beginning to end.&quot;</p>
                        <p>Sarah Belchetz-Swenson</p>
                    </blockquote>
                </div>
            </div>
        </section>
    </main>
</div>