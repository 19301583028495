<div class="whole-page">
    <main>
        <section class="top">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-1"></div>
                    <div class="grid-item-2"></div>
                    <div class="grid-item-3"></div>
                    <div class="grid-item-4"></div>
                    <div class="grid-item-5"></div>
                    <div class="grid-item-6"></div>
                </div>
            </div>
            <div class="content">
                <h1>Visa SMB Tool</h1>
                <div class="main-text">
                    <p>This component on the Visa.com website helps small and medium businesses calculate savings based
                        on digital sales. The user inputs the information for their company, and is shown the results.
                        The user can then adjust the numbers to see and hear the changes. I was fortunate that Visa’s
                        Accessibility QA team helped test this for screen readers. I led the very cross-functional
                        effort to build the component which is part of Visa.com’s AEM CMS.
                    </p>
                </div>
                <div class="center">
                    <img src="assets/img/screenshots/SMB-phone-1.jpg" class="visa-phone"
                        alt="Small and Medium Business Assessment on a phone">
                </div>
            </div>
        </section>
        <section class="second-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-21"></div>
                    <div class="grid-item-22"></div>
                    <div class="grid-item-23"></div>
                    <div class="grid-item-24"></div>
                    <div class="grid-item-25"></div>
                    <div class="grid-item-26"></div>
                    <div class="grid-item-27"></div>
                    <div class="grid-item-28"></div>
                    <div class="grid-item-29"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/SMB-desk-1.png" class="visa-desktop"
                    alt="Small and Medium Business Assessment">
            </div>
        </section>
        <section class="third-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-40"></div>
                    <div class="grid-item-41"></div>
                    <div class="grid-item-42"></div>
                    <div class="grid-item-43"></div>
                    <div class="grid-item-44"></div>
                    <div class="grid-item-45"></div>
                    <div class="grid-item-46"></div>
                    <div class="grid-item-47"></div>
                    <div class="grid-item-48"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/SMB-desk-4.png" class="visa-desktop"
                    alt="Small and Medium Business Assessment results">
            </div>
        </section>
    </main>
</div>