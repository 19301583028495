import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-epoc',
  templateUrl: './epoc.component.html',
  styleUrls: ['./epoc.component.scss']
})
export class EpocComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
