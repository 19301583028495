<div class="whole-page">
    <main>
        <section>
            <div class="grid-holder" aria-hidden="true">
                <div class="aboutflex">
                    <div class="left bg-grid">
                        <div class="grid-item-6-h"></div>
                        <div class="grid-item-7-h"></div>
                        <div class="grid-item-8-h"></div>
                        <div class="grid-item-9-h"></div>
                        <div class="grid-item-10-h"></div>
                        <div class="grid-item-7"></div>
                        <div class="grid-item-9"></div>
                        <div class="grid-item-10"></div>
                    </div>
                    <div class="right bg-grid">
                        <div class="grid-item-1-h"></div>
                        <div class="grid-item-2-h"></div>
                        <div class="grid-item-3-h"></div>
                        <div class="grid-item-4-h"></div>
                        <div class="grid-item-5-h"></div>
                        <div class="grid-item-6"></div>
                        <div class="grid-item-1"></div>
                        <div class="grid-item-2"></div>
                        <div class="grid-item-3"></div>
                        <div class="grid-item-4"></div>
                        <div class="grid-item-5"></div>
                    </div>
                </div>
            </div>
            <div class="content">
                <h1>Miriam Salzer</h1>
                <div class="main-text">
                    <p>
                        <a href="tel:16502388375" title="Phone">650.238.<span class="hideme">remove this</span>8375</a><br>
                        <a href="mailto:&#x6D;&#x69;&#x72;&#x69;&#x61;&#x6D;&#64;&#x6D;&#x73;&#x61;&#x6C;&#x7A;&#x65;&#x72;&#x2E;&#x63;&#x6F;&#x6D;">&#x6D;&#x69;&#x72;&#x69;&#x61;&#x6D;&#64;<span class="hideme">remove this</span>&#x6D;&#x73;&#x61;&#x6C;&#x7A;&#x65;&#x72;&#x2E;&#x63;&#x6F;&#x6D;</a>
                    </p>
                    <p>Hi, I'm Miriam. 
                    As a UI/UX engineer, I'm part of teams that build and maintain web applications. 
                    I live in the San Francisco Bay Area but often work with distributed teams.</p>
                    <p>I work on enterprise applications (and unfortunately can't show screenshots of most of that work), but this is my own personal site where I have fun and experiment. 
                        The only images used on this site are screenshots (everything else is CSS with a bit of HTML.) 
                        I particularly love developing the visual aspects of websites, CSS, and responsive design.
                    </p>
                </div>
            </div>
        </section>
    </main>
</div>
