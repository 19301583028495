<div class="complete {{selectedRoute}}">
    <nav>
        <div>
            <div class="logo">
                <a routerLink="/home" (click)="changeRoute('home')">M. Salzer</a>
            </div>
         
            <div class="dropdown">
                <button type="button" class="hamburger" aria-label="open site menu" (click)="siteListToggle()">
                    <span></span>
                    <span></span>
                    <span></span>
                </button>
                <button type="button" (click)="siteListToggle()" class="website-link" [ngClass]="{'sitelist-open': siteListOpen}" aria-label="open site menu">Work</button>
                <div class="site-list" [ngClass]="{'sitelist-open': siteListOpen}">
                    <ng-container *ngIf="!siteListOpen"> 
                        <span>CJC span</span>
                        <span>Epocrates</span>
                        <span>NLS</span>
                        <span>SBS</span>
                        <span>Visa</span>
                        <span>Vmoso</span>
                        <span>Yodlr span</span>
                    </ng-container>
                    <ng-container *ngIf="siteListOpen">
                        <a routerLink="/cjc" routerLinkActive="active" (click)="changeRoute('cjc')">CJC</a>
                        <a routerLink="/epocrates" routerLinkActive="active" (click)="changeRoute('epocrates')">Epocrates</a>
                        <a routerLink="/nls" routerLinkActive="active" (click)="changeRoute('nls')">NLS</a>
                        <a routerLink="/sbs" routerLinkActive="active" (click)="changeRoute('sbs')">SBS</a>
                        <a routerLink="/visa" routerLinkActive="active" (click)="changeRoute('visa')">Visa</a>
                        <a routerLink="/vmoso" routerLinkActive="active" (click)="changeRoute('vmoso')">Vmoso</a>
                        <a routerLink="/yodlr" routerLinkActive="active" (click)="changeRoute('yodlr')">Yodlr</a>
                        <a href="/blog" target="_self" aria-label="blog">Blog</a>
                    </ng-container>
                </div>
            </div>
            <a href="/blog" class="blog-link">Blog</a>
        </div>
   </nav>
<router-outlet></router-outlet>
