<div class="whole-page epoc">
    <main>
        <section class="top">
                <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-14"></div>
                    <div class="grid-item-7"></div>
                    <div class="grid-item-1"></div>
                    <div class="grid-item-8"></div>
                    <div class="grid-item-12"></div>
                    <div class="grid-item-13"></div>
                </div>
            </div>
            <div class="content">
                <h1>Epocrates</h1>
                <div class="main-text">
                    <p>The Epocrates EHR (Electronic Health Records) application was later bought by Kareo.
                        This was part RoR with SASS and HAML, and part Java. I primarily worked on HMTL and CSS views.
                        On the immunizations module, I acted as Front-End Lead and Designer. I extended the UI from the rest of the product
                        and facilitated communication between the product manager and the backend engineers.</p>
                </div>
            </div>
        </section>
        <section class="second-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-combo">
                        <div class="grid-item-2"></div>
                        <div class="grid-item-3"></div>
                        <div class="grid-item-4"></div>
                    </div>
                    <div class="grid-item-5"></div>
                    <div class="grid-item-22"></div>
                    <div class="grid-item-23"></div>
                    <div class="grid-item-29"></div>
                    <div class="grid-item-28"></div>
                    <div class="grid-item-27"></div>
                    <div class="grid-item-30"></div>
                    <div class="grid-item-31"></div>
                    <div class="grid-item-32"></div>
                    <div class="grid-item-33"></div>
                </div>
            </div>
                
            <div class="content">
                <img src="assets/img/screenshots/imm-edit.png" class="epoc-imm" alt="Epocrates EHR Immunizations page">
            </div>
        </section>
    </main>
</div>