import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CjcrorComponent } from './cjcror/cjcror.component';
import { CjcComponent } from './cjc/cjc.component';
import { EpocComponent } from './epoc/epoc.component';
import { NlsComponent } from './nls/nls.component';
import { SbsComponent } from './sbs/sbs.component';
import { VisaComponent } from './visa/visa.component';
import { VmosoComponent } from './vmoso/vmoso.component';
import { YodlrComponent } from './yodlr/yodlr.component';

const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'cjcror', component: CjcrorComponent },
    { path: 'cjc', component: CjcComponent },
    { path: 'epocrates', component: EpocComponent },
    { path: 'nls', component: NlsComponent },
    { path: 'sbs', component: SbsComponent },
    { path: 'visa', component: VisaComponent },
    { path: 'vmoso', component: VmosoComponent },
    { path: 'yodlr', component: YodlrComponent },
    { path: '', redirectTo: '/home', pathMatch: 'full'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
