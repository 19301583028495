import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SbsComponent } from './sbs/sbs.component';
import { EpocComponent } from './epoc/epoc.component';
import { VmosoComponent } from './vmoso/vmoso.component';
import { YodlrComponent } from './yodlr/yodlr.component';
import { NlsComponent } from './nls/nls.component';
import { CjcrorComponent } from './cjcror/cjcror.component';
import { CjcComponent } from './cjc/cjc.component';
import { HomeComponent } from './home/home.component';
import { VisaComponent } from './visa/visa.component';

@NgModule({
  declarations: [
    AppComponent,
    SbsComponent,
    EpocComponent,
    VmosoComponent,
    YodlrComponent,
    NlsComponent,
    CjcrorComponent,
    CjcComponent,
    HomeComponent,
    VisaComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
