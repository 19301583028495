<div class="whole-page">
    <main>
        <section class="top">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-1"></div> 
                    <div class="grid-item-3"></div> 
                    <div class="grid-item-4"></div>
                    <div class="grid-item-5"></div>
                    <div class="grid-item-6"></div>
                    <div class="grid-item-7"></div>
                    <div class="grid-item-9"></div>
                    <div class="grid-item-10"></div>
                    <div class="grid-item-15"></div>
                    <div class="grid-item-17"></div>
                </div>
            </div>
            <div class="content">
                <h1>Vmoso</h1>
                <div class="main-text">
                <p>This is a screen shot of a prototype for the web version of an application for enterprise collaboration. 
                    <a href="https://www.vmoso.com/mkt/en/index.html" title="vmoso">Vmoso.com</a> has
                    task assignment and sharing, file upload and sharing, a contact section, and text chat. The prototype had mocked up data with
                    no backend. It was HTML, CSS, JavaScript, and icons (some of which I made.) The panels slid in an animated way similar to an ipad 
                    (and had to work on IE8.)
                </p>
                <p>The rigid requirements were to display copious information on the screen all at once. 
                    I used HTML and CSS so that many elements fit, all with provisions for various sizes of screen and content.</p>
                </div>
            </div>
        </section>
        <section class="second-section">
            <div class="grid-holder" aria-hidden="true">
                <div class="bg-grid">
                    <div class="grid-item-42"></div>
                    <div class="grid-item-31"></div>
                    <div class="grid-item-32"></div>
                    <div class="grid-item-33"></div>
                    <div class="grid-item-37"></div>
                    <div class="grid-item-39"></div>
                    <div class="grid-item-35"></div>
                </div>
            </div>
            <div class="content">
                <img src="assets/img/screenshots/bvTasksTrimmed.png" class="vmoso-tasks" alt="Vmoso Tasks page">
            </div>
        </section>
    </main>
</div>

